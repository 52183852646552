<template>
  <div v-show="loading">
    <div class="wrapper" v-bind:style="{'background-color': this.backgroundColor}">
      <i class="fad fa-spin fa-spinner text-center">
      </i>
      <span v-if="text">{{ text }}</span>
    </div>
  </div>


</template>

<script>
export default {
  name: "ComponentLoadSpinnerV2",
  props: {
    loading: {
      required: true,
      default: false
    },
    text: {
      required: false,
      default: ''
    },
    'backgroundColor': {
      required: false,
      default: '#efefef'
    }
  }
}
</script>

<style scoped>

.wrapper {
  min-height: 10rem;
  height: 100%;
  width: 100%;
  padding: 2rem;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr;
  grid-row-gap: 3rem;
  will-change: transform, opacity;
  transform: translateZ(0);
}

i {
  will-change: transform, opacity;
  transform: translateZ(0);
  font-size: 5rem;
  width: 5rem;
}


</style>