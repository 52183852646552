<template>
  <div class="track-and-trace-viewer">
    <TrackAndTraceError v-if="loadStatus === 'error'"/>
    <TrackAndTrace404 v-else-if="!companyName" />
    <TrackAndTraceLoading v-else-if="loadStatus === 'loading-layout'" />
    <TrackAndTraceMissingOrderNumber :orderNumberRaw="orderNumber" :secretKeyRaw="secretKey" @submit="setOrderNumberAndSecretKey($event)" v-else-if="!orderNumber || (!secretKey && getTrackAndTraceConfig.secretKeyLabel)"/>
    <TrackAndTraceLoading v-else-if="loadStatus === 'loading-order-status'" />
    <TrackAndTraceNoData v-else-if="loadStatus === 'finished' && !orderStatus.length"/>
    <TrackAndTrace v-else-if="loadStatus === 'finished' && orderStatus" :orderStatus="orderStatus" :editing="false"/>

    <div class="logo">
      <MotivateLogoWrapper/>
    </div>
  </div>
</template>

<script>
import TrackAndTrace from "../../components/trackAndTrace/TrackAndTrace.vue";
import {mapGetters} from "vuex";
import TrackAndTrace404 from "@/components/trackAndTrace/TrackAndTrace404.vue";
import TrackAndTraceMissingOrderNumber from "@/components/trackAndTrace/TrackAndTraceMissingOrderNumber.vue";
import TrackAndTraceLoading from "@/components/trackAndTrace/TrackAndTraceLoading.vue";
import TrackAndTraceError from "@/components/trackAndTrace/TrackAndTraceError.vue";
import TrackAndTraceNoData from "@/components/trackAndTrace/TrackAndTraceNoData.vue";
import MotivateLogoWrapper from "@/components/trackAndTrace/MotivateLogoWrapper.vue";

export default {
  name: "TrackAndTraceUnauthorized",
  components: {
    TrackAndTraceNoData,
    TrackAndTraceError,MotivateLogoWrapper,
    TrackAndTraceLoading, TrackAndTraceMissingOrderNumber, TrackAndTrace404, TrackAndTrace},
  data() {
    return {
      loadStatus: 'initial',
      orderStatus: null,
      companyName: '',
      orderNumber: '',
      secretKey: '',
      companyId: ''
    }
  },
  computed: {
    ...mapGetters('trackAndTrace', ['getTrackAndTraceConfig']),
    ...mapGetters(['getLocale']),
  },
  methods: {
    async loadOrderStatus() {
      this.loadStatus = 'loading-order-status';

      this.orderStatus = (await this.$FUNCTIONS.TrackAndTrace("getOrderStatus", { companyId: this.companyId, orderNumber: this.orderNumber, secretKey: this.secretKey }).catch(() => {
        this.loadStatus = 'error';
      })).data;
      this.loadStatus = 'finished';
    },
    setOrderNumberAndSecretKey(event){
      this.orderNumber = event.orderNumber;
      this.secretKey = event.secretKey;
      this.loadOrderStatus();
    },
    async initPage() {
      this.loadStatus = 'loading-layout';
      const trackAndTraceCompany = (await this.$FUNCTIONS.TrackAndTrace("getTrackAndTraceCompany", {companyName: this.companyName}).catch(e => {
        this.loadStatus = 'error';
      })).data;


      this.companyId = trackAndTraceCompany.id;
      this.$store.commit('setCompanyId', this.companyId);

      await this.$store.dispatch('trackAndTrace/openDBChannel', {clauses: {where: [['companyId', '==', this.companyId], ['language', '==', this.getLocale]] }}, {});

      this.loadStatus = 'loaded-layout';


      if (this.orderNumber && (this.secretKey || !this.getTrackAndTraceConfig.secretKeyLabel)) {
        this.loadOrderStatus();
      }
    }
  },
  created() {
    this.companyName = this.$route.params.companyName;
    this.orderNumber = this.$route.params.orderNumber;

    let locale = this.$route.query.lang;
    if (!locale) {
      locale = 'nl';
    }
    this.$store.commit('setLocale', locale);
    this.$root.$i18n.locale = this.getLocale;
    this.secretKey = this.$route.params.secretKey ? this.$route.params.secretKey.replace(' ', '').toUpperCase() : '';
    this.initPage();


  },
  watch: {
    'getTrackAndTraceConfig.language': {
      deep: true,
      handler:function() {
        if(!this.$route.query.lang) {
          this.$root.$i18n.locale = this.getTrackAndTraceConfig.language;
        } else {
          this.$root.$i18n.locale = this.getLocale;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.track-and-trace-viewer {
  z-index: 1000001;
  position: absolute;
  background: white;
  min-height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
}



</style>