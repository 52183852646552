<template>
  <div class="track-and-trace-wrapper">
    <TrackAndTraceHeader :editing="false" ref="trackAndTraceHeader" />
    <TrackAndTraceBanner :editing="false" ref="trackAndTraceBanner"/>
    <div class="unknown-order-number-wrapper">
      <div class="row m-0 unknown-order-number-content">
        <div class="p-0  col-12">
          <h5>{{ $t('trackAndTrace.noData.title')}}</h5>
          <p>{{ getTrackAndTraceConfig.orderNotFoundText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrackAndTraceHeader from './TrackAndTraceHeader.vue';
import TrackAndTraceBanner from './TrackAndTraceBanner.vue';
import MotivateLogoWrapper from "@/components/trackAndTrace/MotivateLogoWrapper.vue";
import {mapGetters} from "vuex";

export default {
  name: "TrackAndTraceNoData",
  props: [],
  components: {MotivateLogoWrapper, TrackAndTraceHeader, TrackAndTraceBanner},
  computed: {
    ...mapGetters('trackAndTrace', ['getTrackAndTraceConfig']),
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/MotivateColors";
$informationHeight: 68px;

.track-and-trace-wrapper {
  background: $ultraLightGrey;
  min-height: 100%;
}

@media only screen and (max-width: 900px) {
  .unknown-order-number-wrapper {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
}


.unknown-order-number-wrapper {
  margin-left: 48px;
  margin-right: 48px;
  position: relative;
  top: calc(0px - #{$informationHeight} / 2);
  z-index: 99;
}

.unknown-order-number-content {
  min-height: $informationHeight;
  background: #FFFFFF;
  border: 1px solid #DEE2E6;
  border-radius: 8px;
  padding: 12px 32px;
}
</style>