<template>
  <div class="track-and-trace-wrapper">
    <div class="not-found-wrapper">
      <div class="not-found-content">
        <h5>{{ $t('trackAndTrace.error.title') }}</h5>
        <p>{{ $t('trackAndTrace.error.message') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import MotivateLogoWrapper from "@/components/trackAndTrace/MotivateLogoWrapper.vue";

export default {
  name: "TrackAndTraceError",
  props: [],
  components: {MotivateLogoWrapper},
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/MotivateColors";

.track-and-trace-wrapper {
  background: $ultraLightGrey;
  min-height: 100%;
}

.not-found-wrapper {
  padding: 48px;
}

.not-found-content {
  background: $walterWhite;
  border: 1px solid $grey;
  border-radius: 8px;
  padding: 12px 32px;
}

</style>