<template>
  <div class="track-and-trace-wrapper">
    <TrackAndTraceHeader :editing="false" ref="trackAndTraceHeader" />
    <TrackAndTraceBanner :editing="false" ref="trackAndTraceBanner"/>
    <div class="missing-order-number-wrapper">
      <div class="row m-0 missing-order-number-content">
        <div v-if="!orderNumberRaw" class="col">
            <label for="order-number-input">{{  $t('trackAndTrace.missingOrderNumber.label') }}</label>
            <input @keyup.enter="submitOrderNumber" type="text" id="order-number-input" class="form-control" v-model="orderNumber" >
        </div>
        <div v-if="getTrackAndTraceConfig.secretKeyLabel && !secretKeyRaw" class="col">
          <label for="order-number-input">{{  getTrackAndTraceConfig.secretKeyLabel }}</label>
          <input @keyup.enter="submitOrderNumber" type="text" id="order-number-input" class="form-control" v-model="secretKey" >
        </div>
        <div class="col-12">
          <button :disabled="!orderNumber || (getTrackAndTraceConfig.secretKeyLabel && !secretKey)" class="motivate-primary-button" @click="submitOrderNumber()">{{  $t('trackAndTrace.missingOrderNumber.submit') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrackAndTraceHeader from './TrackAndTraceHeader.vue';
import TrackAndTraceBanner from './TrackAndTraceBanner.vue';
import {mapGetters} from "vuex";

export default {
  name: "TrackAndTraceMissingOrderNumber",
  props: ['orderNumberRaw', 'secretKeyRaw'],
  data() {
    return {
      orderNumber: '',
      secretKey: ''
    }
  },
  components: {TrackAndTraceHeader, TrackAndTraceBanner},
  computed: {
    ...mapGetters('trackAndTrace', ['getTrackAndTraceConfig']),
  },
  methods: {
    submitOrderNumber() {
      this.$emit('submit', { orderNumber: this.orderNumber, secretKey: this.secretKey.replace(' ', '').toUpperCase() })
    }
  },
  created() {
    if (this.orderNumberRaw) {
      this.orderNumber = this.orderNumberRaw;
    }

    if (this.secretKeyRaw) {
      this.secretKey = this.secretKeyRaw;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/MotivateColors";
$informationHeight: 68px;

.track-and-trace-wrapper {
  background: $ultraLightGrey;
  min-height: 100%;
}


.motivate-primary-button {
  margin-top: 12px;
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .missing-order-number-wrapper {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
}


.missing-order-number-wrapper {
  margin-left: 48px;
  margin-right: 48px;
  position: relative;
  top: calc(0px - #{$informationHeight} / 2);
  z-index: 99;
}

.missing-order-number-content {
  min-height: $informationHeight;
  background: #FFFFFF;
  border: 1px solid #DEE2E6;
  border-radius: 8px;
  padding: 12px 32px;
}
</style>