<template>
  <div class="track-and-trace-wrapper">
    <div class="loading-wrapper">
      <div class="loading-content">
        <h5>{{ $t('trackAndTrace.loading.title')  }}</h5>
        <p>{{  $t('trackAndTrace.loading.message')  }}</p>
        <ComponentLoadSpinnerV2 :loading="true" :background-color="'#FFFFFF'" />
      </div>
    </div>
  </div>
</template>

<script>
import ComponentLoadSpinnerV2 from "@/components/general/ComponentLoadSpinnerV2.vue";
import MotivateLogoWrapper from "@/components/trackAndTrace/MotivateLogoWrapper.vue";

export default {
  name: "TrackAndTraceLoading",
  components: {MotivateLogoWrapper, ComponentLoadSpinnerV2 }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/MotivateColors";

.track-and-trace-wrapper {
  background: $ultraLightGrey;
  min-height: 100%;
}

.loading-wrapper {
  padding: 48px;
}

.loading-content {
  background: $walterWhite;
  border: 1px solid $grey;
  border-radius: 8px;
  padding: 12px 32px;
}


</style>